<template>

<!-- 忘记密码页面 -->
  <div class="container">
    <div class="page-container">
      <div class="body-container">
        <div class="logo-section">
          <img src="@/assets/logo.png" alt="" class="logo-image_look">
          <div class="logo-text_section">
            <p class="logo-title-ch">视力健康管理系统</p>
            <p class="logo-title-en">Vision health management system</p>
          </div>
        </div>
        <div v-if="show == 1" class="forgot_password">
          <div class="login-header">
            <div class="link"></div>
            <p class="login-header-text">
              忘记密码
            </p>
          </div>
          <el-form :model="forgotPassword" status-icon :rules="forgotPasswordRules" ref="forgotPasswordRef" label-width="100px" class="demo-forgotPasswordData">
            <el-form-item prop="phone" label-width="0">
              <el-input placeholder="请输入手机号码" maxlength="11" v-model="forgotPassword.phone" autocomplete="on">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="imageCode" label-width="0">
              <el-input placeholder="请输入图形验证码" maxlength="4" v-model="forgotPassword.imageCode" autocomplete="off">
                <template slot="append">
                  <div class="imageCode-section_false" @click="getImageCode">
                    <el-image :src="newImageCode" fit="contain">
                      <div slot="error" class="image-slot">
                        获取图形验证码
                      </div>
                    </el-image>
                  </div>
                  <!-- <div class="imageCode-section_true">
                    <img src="" alt="" class="imageCode-image">
                    <img src="" alt="" class="refresh_image">
                  </div> -->
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="phoneCode" label-width="0">
              <el-input placeholder="请输入手机验证码" maxlength="6" :disabled="!judgeGetEddPhoneCodes" v-model="forgotPassword.phoneCode" autocomplete="off">
                <template slot="append">
                  <el-button class="getPhoneCode-button" :disabled="judgeGetEddPhoneCodes" @click="getPhoneCode">{{getEddPhoneCodes ? getEddPhoneCodes + '秒后发送验证码' : '获取验证码'}}</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-button class="next_button" @click="judgePhoneCode">下一步</el-button>
            <router-link class="forget" :to="{name: 'login'}">
              <el-button class="forget-button" type="text">账号密码登录</el-button>
            </router-link>
          </el-form>
        </div>

        <div  v-if="show == 2" class="new_password-section">
          <div class="login-header">
            <div class="link"></div>
            <p class="login-header-text">
              忘记密码
            </p>
          </div>
          <el-form :model="newPassword" status-icon :rules="newPasswordRules" ref="newPasswordRef" label-width="100px" class="demo-userData">
            <el-form-item prop="newCode" label-width="0">
              <el-input type="password" show-password placeholder="请输入新密码（请设置6位数以上的密码）" v-model="newPassword.newCode" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item prop="newCodes" label-width="0">
              <el-input type="password" show-password placeholder="再次输入新密码" v-model="newPassword.newCodes" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <el-button class="login_button" @click="judgePassword">完成</el-button>
          <router-link class="forget" :to="{name: 'login'}">
            <el-button class="forget-button" type="text">账号密码登录</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import passwordService from '@/globals/service/management/password.js'
import storage from '@/globals/storage/index.js'
import api from '@/globals/request/api.js'
export default {
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.newPassword.newCode) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 手机验证码倒计时
      getEddPhoneCodes: null,
      // 图形验证码校验 返回信息
      msgId: null,
      newImageCode: '',
      show: 1,
      newPassword: {
        newCode: '',
        newCodes: ''
      },
      newPasswordRules: {
        newCode: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、])|(?=.*\d)(?=.*[?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]))[a-z\d?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{6,20}/i,
            message: '请输入数字和字母或者特殊符号，长度在6到20之间',
            trigger: 'blur'
          }
        ],
        newCodes: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          {
            pattern: /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、])|(?=.*\d)(?=.*[?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]))[a-z\d?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{6,20}/i,
            message: '请输入数字和字母或者特殊符号，长度在6到20之间',
            trigger: 'blur'
          },
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      forgotPassword: {
        phone: '',
        imageCode: '',
        phoneCode: ''
      },
      forgotPasswordRules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ],
        imageCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ],
        phoneCode: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    judgeGetEddPhoneCodes () {
      return this.getEddPhoneCodes > 0 || this.getEddPhoneCodes !== null
    }
  },
  methods: {
    judgePassword () {
      this.$refs.newPasswordRef.validate(vaild => {
        if (vaild) {
          // if (this.newPassword.newCode === this.newPassword.newCodes) return this.$message({ type: 'info', message: '两次密码不相同,请重新填写' })
          const data = {
            phone: this.forgotPassword.phone,
            code: this.forgotPassword.code,
            password: this.newPassword.newCode,
            msgId: this.msgId
          }
          passwordService.editForgetPass(data).then(res => {
            this.$message({
              message: '修改成功，请重新登陆',
              type: 'success'
            })
            storage.clear()
            this.$router.push({ name: 'login' })
          })
        }
      })
    },
    judgePhoneCode () {
      this.$refs.forgotPasswordRef.validate(vaild => {
        if (vaild) {
          if (!this.newImageCode) return this.$message({ type: 'info', message: '请先获取图形验证码' })
          if (!this.msgId) return this.$message({ type: 'info', message: '请先获取手机验证码' })
          const data = {
            phone: this.forgotPassword.phone,
            msgId: this.msgId,
            code: this.forgotPassword.phoneCode
          }
          passwordService.getvalidation(data).then(res => {
            this.show = 2
          })
        }
      })
    },
    getPhoneCode () {
      this.$refs.forgotPasswordRef.validateField('phone', (vaild) => {
        if (vaild) return this.$message({ type: 'info', message: '请填写手机号码' })
        this.$refs.forgotPasswordRef.validateField('imageCode', (vailds) => {
          if (!this.newImageCode) return this.$message({ type: 'info', message: '请先获取图形验证码' })
          if (vailds) return this.$message({ type: 'info', message: '请填写图形验证码' })
          const data = {
            inCode: this.forgotPassword.imageCode,
            phone: this.forgotPassword.phone
          }
          const codeDate = storage.get('codeDate')
          if (codeDate) return this.$message({ type: 'info', message: '验证码已发送，请勿重复请求' })
          passwordService.getVerifyCode(data).then(res => {
            const dataS = {
              phone: this.forgotPassword.phone,
              inCode: this.forgotPassword.imageCode
            }
            passwordService.getsendSMS(dataS).then(res => {
              storage.setDate('codeDate', 1, 60)
              this.$message({ type: 'success', message: '验证码已发送' })
              this.msgId = res.data
              this.getEddPhoneCodes = 60
              const getEddPhoneCode = setInterval(() => {
                this.getEddPhoneCodes -= 1
                if (this.getEddPhoneCodes === 0) {
                  clearInterval(getEddPhoneCode)
                  this.getEddPhoneCodes = null
                }
              }, 1000)
            })
          })
        })
      })
    },
    getImageCode () {
      this.$refs.forgotPasswordRef.validateField('phone', (vaild) => {
        if (!vaild) {
          this.newImageCode = api.management.password.getShearCaptcha + '?phone=' + this.forgotPassword.phone + '&v=' + Math.random()
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  width: 100%;
  height: 100%;
  background-image: url('./../assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-container{
  text-align: center;
  transform: translateY(-10%);
  .body-container{
    .logo-section{
      display: flex;
      align-items: center;
      .logo-image_look{
        width: 92px;
        height: 70px;
        margin-right: 20px;
      }
      .logo-text_section{
        color: #fff;
        text-align: left;
        .logo-title-ch{
          font-size: 36px;
          font-weight: 500;
          line-height: 50px;
          letter-spacing: 15px;
        }
        .logo-title-en{
          font-size: 24px;
          line-height: 33px;
        }
      }
    }
    .new_password-section{
      width: 520px;
      margin: 130px 0 0;
      background-color: #041E59;
      border-radius: 8px;
      padding: 39px 124px  46px 122px;
      font-size: 0;
      text-align: left;
      .login-header{
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 300;
        margin-bottom: 36px;
        .link{
          width: 4px;
          height: 20px;
          background-color: #05BAEC;
          margin-right: 9px;
          border-radius: 5px;
        }
      }
      /deep/.el-input__inner{
        color: #fff !important;
        border: none !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        border-bottom: 1px solid #5E92A4 !important;
        &::-webkit-input-placeholder{
          font-size: 13px !important;
        }
        &:focus{
          color: #fff !important;
          border: none !important;
          border-radius: 0 !important;
          background-color: transparent !important;
          border-bottom: 1px solid #5E92A4 !important;
        }
      }
      .login_button{
        width: 100%;
        background: rgba(52, 187, 236, 0.4) !important;
        outline: rgba(52, 187, 236, 0.4) !important;
        color: #fff;
        border-color: rgba(52, 187, 236, 0.4) !important;;
        &:hover{
          outline: #34BBEC !important;
          border-color: #34BBEC !important;;
          background-color: #34BBEC !important;
        }
      }
      .forget{
          text-decoration: none;
         color: #D2D6D8;
         display: inline-block;
        .forget-button{
         color: #D2D6D8;
          display: block;
          text-align: left;
          font-weight: 300;
        }
      }
    }
    .forgot_password{
       width: 520px;
      margin: 130px 0 0;
      background-color: #041E59;
      border-radius: 8px;
      padding: 39px 124px  46px 122px;
      font-size: 0;
      .login-header{
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 300;
        margin-bottom: 36px;
        .link{
          width: 4px;
          height: 20px;
          background-color: #05BAEC;
          margin-right: 9px;
          border-radius: 5px;
        }
      }
      /deep/.el-input-group__append, .el-input-group__prepend{
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid #5E92A4 !important;
        padding: 0 !important;
        text-align: right;
        &:focus{
          color: #fff !important;
          border: none !important;
          border-radius: 0 !important;
          background-color: transparent !important;
          border-bottom: 1px solid #5E92A4 !important;
        }
      }
      /deep/.el-input__inner{
        color: #fff !important;
        border: none !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        border-bottom: 1px solid #5E92A4 !important;
        &:focus{
          color: #fff !important;
          border: none !important;
          border-radius: 0 !important;
          background-color: transparent !important;
          border-bottom: 1px solid #5E92A4 !important;
        }
      }
      .next_button{
        width: 100%;
        background: rgba(52, 187, 236, 0.4) !important;
        outline: rgba(52, 187, 236, 0.4)  !important;
        color: #fff;
        border-color: rgba(52, 187, 236, 0.4)   !important;;
        &:hover{
          outline: #34BBEC !important;
          border-color: #34BBEC !important;;
          background-color: #34BBEC !important;
        }
      }
      .login{
        color: #D2D6D8;
        display: block;
        text-align: left;
        font-weight: 300;
      }
      .forget{
          text-decoration: none;
         color: #D2D6D8;
        .forget-button{
         color: #D2D6D8;
          display: block;
          text-align: left;
          font-weight: 300;
        }
      }
      .is-disabled{
        background-color: transparent !important;
        border: none !important;
        border-radius: 0 !important;
      }
    }
  }
}/deep/.el-input-group__prepend{
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #5E92A4 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  color: #fff !important;
  &:focus{
    color: #fff !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    border-bottom: 1px solid #5E92A4 !important;
  }
}
/deep/.imageCode-section_false{
  font-size: 12px;
  width: 112px;
  height: 38px;
  line-height: 38px;
}
.demo-forgotPasswordData, .demo-userData{
  text-align: left;
  .forget{
    display: inline-block;
  }
}
</style>
