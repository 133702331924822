import axios from './../../request/axios.js'
import api from './../../request/api.js'

const teacherService = {
  getVerifyCode: function (params) {
    return axios.post(api.management.password.getVerifyCode, params)
  },
  getsendSMS: function (params) {
    return axios.post(api.management.password.getsendSMS, params)
  },
  getvalidation: function (params) {
    return axios.post(api.management.password.getvalidation, params)
  },
  editForgetPass: function (params) {
    return axios.post(api.management.password.editForgetPass, params)
  }
}
export default teacherService
