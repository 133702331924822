<template>
  <div class="container">
    <div class="page-container">
      <div class="body-container">
        <div class="logo-section">
          <img src="@/assets/logo.png" alt="" class="logo-image_look">
          <div class="logo-text_section">
            <p class="logo-title-ch">视力健康管理系统</p>
            <p class="logo-title-en">Vision health management system</p>
          </div>
        </div>
        <div class="login-container">
          <div class="login-header">
            <div class="link"></div>
            <p class="login-header-text">
              登陆
            </p>
          </div>
          <el-form :model="userData" status-icon :rules="userDataRules" ref="userData" label-width="100px" class="demo-userData">
            <el-form-item prop="phone" label-width="0">
              <el-input placeholder="请输入手机号码" maxlength="11" v-model="userData.phone" autocomplete="on">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label-width="0">
              <el-input @paste.native.capture.prevent="handlePaste" :show-password="true" type="password" placeholder="请输入密码" maxlength="20"  v-model="userData.password" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <el-button class="login_button" :disabled="loginDisabled" @click="login">登录</el-button>
          <router-link class="forget-link" :to="{name: 'ChangePassword'}">
            <el-button class="forget" type="text">忘记密码 ?</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import loginService from '@/globals/service/detection/login.js'
import loginService from '@/globals/service/management/login.js'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      loginDisabled: false,
      userData: {
        phone: '',
        password: ''
      },
      userDataRules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码' },
          { required: true, max: 20, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、])|(?=.*\d)(?=.*[?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]))[a-z\d?`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{6,20}/i,
            message: '请输入数字和字母或者特殊符号，长度在6到20之间',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    // this.delSessionStorage()
    this.getStorage()
    this.keyupSubmit()
    // console.log('1', this.loginKey)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.getKeyupSubmit)
  },
  methods: {
    keyupSubmit () {
      window.addEventListener('keydown', this.getKeyupSubmit)
    },
    // 回车事件监听
    getKeyupSubmit () {
      const key = window.event.keyCode
      if (key === 13) {
        this.login()
      }
    },
    handlePaste () {
      // 粘贴会触发来到这里,无操作就行
      return false
    },
    getStorage () {
      const loginKey = storage.get('loginKey')
      const roleType = Number(storage.get('roleType'))
      const uid = storage.get('uid')
      const sysName = storage.get('sysName')
      // console.log('1', loginKey)
      // console.log('2', roleType)
      // console.log('3', uid)
      // console.log('4', sysName)
      if (loginKey && roleType && uid && sysName) {
        console.log('11')
        this.$message({
          message: '你已登陆',
          type: 'success'
        })
        if (roleType === 1) {
          // 机构管理
          return this.$router.push({ name: 'managementOrganization' })
        }

        // 首页
        this.$router.push({
          name: 'managementHome'
        })
      }
      // else {
      //   return this.login()
      // }
    },
    delSessionStorage () {
      ['loginKey', 'roleType', 'uid', 'sysName'].forEach(item => {
        // console.log(item)
        sessionStorage.removeItem(item)
      })
    },
    login () {
      // validate 对整个表单进行校验的方法，参数为一个回调函数
      this.$refs.userData.validate(valid => {
        if (valid) {
          // 发送请求login
          loginService.login(this.userData).then(res => {
            // console.log('res', res)
            // 发起请求后禁止点击
            this.loginDisabled = true
            // console.log(res)
            for (const key in res.data) {
              // 遍历获取res.data的 键值对
              storage.set(key, res.data[key])
              // console.log(key)
            }
            // 储存在本地
            sessionStorage.setItem('loginKey', res.data.loginKey)
            sessionStorage.setItem('roleType', res.data.roleType)
            sessionStorage.setItem('uid', res.data.uid)
            sessionStorage.setItem('sysName', res.data.sysName)

            localStorage.setItem('loginKey', res.data.loginKey)
            localStorage.setItem('roleType', res.data.roleType)
            localStorage.setItem('uid', res.data.uid)
            localStorage.setItem('sysName', res.data.sysName)
            // storage.set('loginKey', res.data.loginKey)
            // storage.set('roleType', res.data.roleType)
            // storage.set('uid', res.data.uid)
            // storage.set('sysName', res.data.sysName)
            // storage.set('role_type', res.data.role_type)
            // storage.set('schoolId', res.data.schoolId)
            // location.reload()
            if (res.data.roleType !== '1') {
              this.$router.push({ name: 'managementHome' })
            } else {
              this.$router.push({ name: 'managementOrganization' })
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
*{
   -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.container{
  width: 100%;
  height: 100%;
  background-image: url('./../assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-container{
  text-align: center;
  transform: translateY(-10%);
  .body-container{
    .logo-section{
      display: flex;
      align-items: center;
      .logo-image_look{
        width: 92px;
        height: 70px;
        margin-right: 20px;
      }
      .logo-text_section{
        color: #fff;
        text-align: left;
        .logo-title-ch{
          font-size: 36px;
          font-weight: 500;
          line-height: 50px;
          letter-spacing: 15px;
        }
        .logo-title-en{
          font-size: 24px;
          line-height: 33px;
        }
      }
    }
    .login-container{
      width: 520px;
      margin: 130px 0 0;
      background-color: #041E59;
      border-radius: 8px;
      padding: 39px 124px  46px 122px;
      font-size: 0;
      .login-header{
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 300;
        margin-bottom: 36px;
        .link{
          width: 4px;
          height: 20px;
          background-color: #05BAEC;
          margin-right: 9px;
          border-radius: 5px;
        }
      }
      /deep/.el-input__inner{
        color: #fff !important;
        border: none !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        border-bottom: 1px solid #5E92A4 !important;
        &:focus{
          color: #fff !important;
          border: none !important;
          border-radius: 0 !important;
          background-color: transparent !important;
          border-bottom: 1px solid #5E92A4 !important;
        }
      }
      .login_button{
        width: 100%;
        background: rgba(52, 187, 236, 0.4) !important;
        outline: rgba(52, 187, 236, 0.4) !important;
        color: #fff;
        border-color: rgba(52, 187, 236, 0.4) !important;;
        &:hover{
          outline: #34BBEC !important;
          border-color: #34BBEC !important;;
          background-color: #34BBEC !important;
        }
      }
      .forget-link{
        text-decoration: none;
        color: D2D6D8;
        .forget{
          color: #D2D6D8;
          display: block;
          text-align: left;
          font-weight: 300;
        }
      }
    }
  }
}/deep/.el-input-group__prepend{
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid #5E92A4 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  color: #fff !important;
  -webkit-box-shadow: 0 0 0 400px transparent inset;
  &:focus{
    color: #fff !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    border-bottom: 1px solid #5E92A4 !important;
  }
}
input:-webkit-autofill{
-webkit-box-shadow: 0 0 0 400px #E8ECED inset;
}
.login-container{
  text-align: left;
  .forget-link{
    display: inline-block;
  }
}
/deep/input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {

-webkit-text-fill-color: #ededed !important;

-webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;

    background-color:transparent;

    background-image: none;

     transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间

}
input {
background-color:transparent;
}
</style>
